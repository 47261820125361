import * as R from 'ramda'

import * as analytics from '@rushplay/analytics'
import * as api from '@rushplay/api-client'
import * as session from '@rushplay/session'

import * as Constants from '../constants'
import * as player from '../player'
import * as Ui from '../ui'
import { getMapsPlayerId } from '../session'

import * as types from './types'

export * from './actions'
export * from './types'

function isPresent(values) {
  return R.none(R.isNil, values)
}

export const gtmEvents = {
  [analytics.AUTHENTICATED]: (action, prevState, nextState) => {
    const uid = session.getUsername(nextState.session)
    const affiliateid = session.getAffiliateId(nextState.session)

    if (!isPresent([uid])) {
      return null
    }

    return {
      event: 'login',
      user: {
        uid,
        affiliateid,
      },
    }
  },

  [analytics.DEPOSITED]: (action, prevState, nextState) => {
    const uid = session.getUsername(nextState.session)
    const affiliateid = session.getAffiliateId(nextState.session)
    const depositCount = player.getDepositCount(nextState.player)
    const valueCents = player.getLastDepositAmount(nextState.player)
    const walletActive = Ui.getVisibility(nextState.ui, {
      id: Constants.TransactionType.DEPOSIT,
    })

    if (!isPresent([depositCount, valueCents, uid])) {
      return null
    }

    return {
      event: Constants.TransactionType.DEPOSIT,
      active: {
        wallet: walletActive,
      },
      deposit: {
        count: depositCount,
        value: Math.round(valueCents) / 100,
        currency: 'EUR',
      },
      user: {
        uid,
        affiliateid,
      },
    }
  },

  [analytics.PAGE_VIEWED]: action => ({
    event: 'pageview',
    page: {
      path: action.payload.path,
      title: action.payload.title,
    },
  }),

  [analytics.REGISTRATION_STARTED]: (action, prevState, nextState) => {
    const affiliateid = session.getAffiliateId(nextState.session)

    return {
      event: 'registrationStarted',
      user: {
        affiliateid,
      },
      registrationType: R.path(['payload', 'registrationType'], action),
    }
  },

  [analytics.REGISTERED]: (action, prevState, nextState) => {
    const uid = session.getUsername(nextState.session)
    const affiliateid = session.getAffiliateId(nextState.session)

    if (!isPresent([uid])) {
      return null
    }

    return {
      event: 'nrc',
      user: {
        uid,
        affiliateid,
      },
    }
  },

  [analytics.BONUS_CLAIMED]: (action, prevState, nextState) => {
    const currency = session.getCurrency(nextState.session)

    if (!isPresent([currency])) {
      return null
    }

    return {
      event: 'bonus',
      bonus: {
        value: R.divide(action.payload.value, 100),
        currency: session.getCurrency(nextState.session),
        claimed: action.payload.claimed ? 'yes' : 'no',
      },
    }
  },

  [types.OPEN_WALLET]: action => {
    return {
      event: 'wallet-open',
      page: {
        path: action.payload.path,
        title: action.payload.title,
        active: {
          menu: action.payload.menuActive,
        },
      },
    }
  },

  [types.OPEN_SIDE_MENU]: action => {
    return {
      event: 'menu-open',
      page: {
        path: action.payload.path,
        title: action.payload.title,
      },
    }
  },

  [types.CLICK_SIDE_MENU_ITEM]: action => {
    return {
      event: 'menu-item-click',
      page: {
        path: action.payload.path,
      },
      menu: {
        title: action.payload.title,
        destination: action.payload.destination,
        expanded: action.payload.expanded,
      },
    }
  },

  [types.ACCEPT_COOKIE_CONSENT]: () => {
    return {
      event: 'cookie-consent',
      user: {
        acceptedCookies: true,
      },
    }
  },
}

export function analyticsRegistration() {
  return api.fetchNotificationsByKind('analytics', {
    success: res => {
      const signUpNotification = R.find(R.propEq('event', 'signup'), res.value)

      if (signUpNotification) {
        return api.deleteNotification(signUpNotification.id, {
          success: analytics.register,
          version: 1,
        })
      }
    },
    version: 1,
  })
}

export const mapsEvents = {
  [analytics.DEPOSITED]: (action, prevState, nextState) => {
    const btag = session.getBtag(nextState.session)
    const valueCents = player.getLastDepositAmount(nextState.player)
    const playerId = getMapsPlayerId(nextState.session)

    if (!isPresent([valueCents, playerId])) {
      return null
    }

    return {
      actionName: 'deposit',
      btag,
      playerId,
      deposit: {
        value: valueCents / 100,
      },
    }
  },
  [analytics.REGISTERED]: (action, prevState, nextState) => {
    const btag = session.getBtag(nextState.session)
    const playerId = getMapsPlayerId(nextState.session)

    if (!isPresent([playerId])) {
      return null
    }

    return {
      actionName: 'registration',
      btag,
      playerId,
    }
  },
}
